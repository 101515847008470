<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-3">
      <div class="col-6 col-sm-4 col-md-3 mb-3">
        <div class="tournament text-center" v-if="scorer">
          <h5 class="mb-0">{{ $tc('scorer', 1) }}</h5>
          <hr>
          <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: scorer.slug } }">
            <div class="player-card mb-3">
              <img :src="scorer.card" :alt="`Tarjeta ${scorer.nick}`" class="img-card">
              <div class="player-card-left">
                <span class="overall border-text-black">{{ scorer.overall }}</span>
                <span class="position border-text-black">{{ scorer.position }}</span>
                <img v-if="scorer.team" :src="scorer.team.image" :alt="scorer.team.name" :title="scorer.team.name" class="img-team">
                <img :src="scorer.country_flag" :alt="`Pais de ${scorer.nick}`" class="img-country">
              </div>
              <div class="player-card-right">
                <img :src="scorer.avatar" :alt="`Avatar de ${scorer.nick}`" class="img-avatar">
              </div>
              <h2 class="player-card-user border-text-black">{{ scorer.nick }}</h2>
              <div class="player-card-chemstyle">
                <img :src="scorer.chem_style_image" :alt="`Chemstyle de ${scorer.nick}`">
              </div>
            </div>
          </router-link>
          <h6 class="player-card-quantity">{{ `${scorer.goals} ${$tc('goal', scorer.goals)}` }}</h6>
        </div>
      </div>
      <div v-if="assistance" class="col-6 col-sm-4 col-md-3 mb-3">
        <div class="tournament text-center">
          <h5 class="mb-0">{{ $t('assistances') }}</h5>
          <hr>
          <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: assistance.slug } }">
            <div class="player-card mb-3">
              <img :src="assistance.card" :alt="`Tarjeta ${assistance.nick}`" class="img-card">
              <div class="player-card-left">
                <span class="overall border-text-black">{{ assistance.overall }}</span>
                <span class="position border-text-black">{{ assistance.position }}</span>
                <img v-if="assistance.team" :src="assistance.team.image" :alt="assistance.team.name" :title="assistance.team.name" class="img-team">
                <img :src="assistance.country_flag" :alt="`Pais de ${assistance.nick}`" class="img-country">
              </div>
              <div class="player-card-right">
                <img :src="assistance.avatar" :alt="`Avatar de ${assistance.nick}`" class="img-avatar">
              </div>
              <h2 class="player-card-user border-text-black">{{ assistance.nick }}</h2>
              <div class="player-card-chemstyle">
                <img :src="assistance.chem_style_image" :alt="`Chemstyle de ${assistance.nick}`">
              </div>
            </div>
          </router-link>
          <h6 class="player-card-quantity">{{ `${assistance.total} ${$tc('assistance', assistance.total)}` }}</h6>
        </div>
      </div>
      <div v-if="gk" class="col-6 col-sm-4 col-md-3 mb-3">
        <div class="tournament text-center">
          <h5 class="mb-0">{{ $tc('goalkeeper', 1) }}</h5>
          <hr>
          <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: gk.slug } }">
            <div class="player-card mb-3">
              <img :src="gk.card" :alt="`Tarjeta ${gk.nick}`" class="img-card">
              <div class="player-card-left">
                <span class="overall border-text-black">{{ gk.overall }}</span>
                <span class="position border-text-black">{{ gk.position }}</span>
                <img v-if="gk.team" :src="gk.team.image" :alt="gk.team.name" :title="gk.team.name" class="img-team">
                <img :src="gk.country_flag" :alt="`Pais de ${gk.nick}`" class="img-country">
              </div>
              <div class="player-card-right">
                <img :src="gk.avatar" :alt="`Avatar de ${gk.nick}`" class="img-avatar">
              </div>
              <h2 class="player-card-user border-text-black">{{ gk.nick }}</h2>
              <div class="player-card-chemstyle">
                <img :src="gk.chem_style_image" :alt="`Chemstyle de ${gk.nick}`">
              </div>
            </div>
          </router-link>
          <h6 class="player-card-quantity">{{ `${gk.total} ${$tc('point', gk.total)}` }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      scorer: null,
      assistance: null,
      gk: null
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', 'bests')
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.teamSlug
      const path = `${lang}/console/${console}/team/${slug}/the-bests`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.scorer = data.scorer
        this.assistance = data.assist
        this.gk = data.gk
      })
    }
  }
}
</script>
